import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				About | EcoDrive Service Center
			</title>
			<meta name={"description"} content={"EcoDrive Service Center – сучасна станція техобслуговування авто, яка спеціалізується на встановленні та обслуговуванні систем ГБО."} />
			<meta property={"og:title"} content={"About | EcoDrive Service Center"} />
			<meta property={"og:description"} content={"EcoDrive Service Center – сучасна станція техобслуговування авто, яка спеціалізується на встановленні та обслуговуванні систем ГБО."} />
			<link rel={"shortcut icon"} href={"https://arataplesta.com/imgs/6456819.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<meta name={"msapplication-TileImage"} content={"https://arataplesta.com/imgs/6456819.png"} />
			<meta name={"msapplication-TileColor"} content={"https://arataplesta.com/imgs/6456819.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://arataplesta.com/imgs/pexels-andrea-piacquadio-3807277.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Наші послуги{"\n\n"}
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 16px/1.5 --fontFamily-sansVerdana">
						Обираючи EcoDrive Service Center, ви отримуєте найкращий сервіс для вашого автомобіля. Ми завжди готові бути вашими надійними партнерами на дорозі до екологічної та економічної автоподорожі, забезпечивши вам не лише якісний сервіс для автомобіля, але і максимальний комфорт, знання і зручність під час візиту до нашого сервісного центру.{"\n\n"}
					</Text>
					<Button
						background="--color-primary"
						color="--light"
						border-width="2px"
						border-style="solid"
						border-color="--color-darkL1"
						padding="12px 30px 12px 30px"
						font="normal 300 16px/1.5 --fontFamily-mono"
						type="link"
						text-decoration-line="initial"
						href="/contact-us"
					>
						Контакти
					</Button>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(3, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="#d9fd6e"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									01
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								<Strong>
									Встановлення систем ГБО{"\n\n"}
								</Strong>
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet">
								Наші кваліфіковані фахівці здійснюють професійне встановлення систем ГБО, щоб ваш автомобіль міг працювати на автогазі, що дозволить значно зменшити витрати на пальне.{"\n\n"}
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="#fddd6e"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									02
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								<Strong>
									{"\n"}Обслуговування та регулювання{"\n\n"}
								</Strong>
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet">
								Ми надаємо регулярне обслуговування та налаштування системи ГБО, щоб забезпечити її оптимальну ефективність та безпеку.{"\n\n"}
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="#fdae6e"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									03
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								<Strong>
									{"\n"}Діагностика та ремонт{"\n\n"}
								</Strong>
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet">
								Наші спеціалісти використовують сучасну діагностичну техніку для виявлення будь-яких проблем з системою ГБО та проводять їхній ремонт в найкоротший термін.{"\n\n"}
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="#fdae6e"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									04
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								<Strong>
									{"\n"}Екологічні рішення{"\n\n"}
								</Strong>
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet">
								EcoDrive Service Center дбає про навколишнє середовище, сприяючи переходу на більш чисте пальне та зменшуючи викиди CO2.{"\n\n"}
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="#d9fd6e"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									05
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								<Strong>
									{"\n"}Регулярний технічний огляд{"\n\n"}
								</Strong>
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet">
								Наші майстри проводять всебічний технічний огляд вашого автомобіля, щоб виявити потенційні проблеми та запропонувати оптимальні рішення для їх виправлення.{"\n\n"}
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								background="#fddd6e"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									06
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								<Strong>
									{"\n"}Модернізація та тюнінг{"\n\n"}
								</Strong>
							</Text>
							<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet">
								Ми допоможемо вам змінити або покращити елементи вашого автомобіля, від підвіски та вихлопної системи до встановлення сучасних систем навігації та розваг.{"\n\n"}
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Advantages />
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523c308e2dd3a0022204417"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});